.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 10px ;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  max-width: 500px; /* Ensure a reasonable max-width */
  margin: auto; /* Center horizontally */
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #282c34;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  color: white;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}

.button {
  margin: 0 0.3rem 1.2rem 0;
  padding: 0 3rem;
}

.btn-primary {
  font-size: 1.5rem;
  display: block;
  letter-spacing: 0.2rem;
  height: 6.6rem;
  line-height: 6.6rem;
  padding: 0 3rem;
  margin-top: 4.8rem;
  background: hsl(350, 100%, 30%);
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus {
  background: hsl(350, 89%, 43%);
  color: #ffffff;
}

.btn-secondary {
  font-size: 1.5rem;
  display: block;
  letter-spacing: 0.2rem;
  height: 6.6rem;
  line-height: 6.6rem;
  padding: 0 3rem;
  margin-top: 4.8rem;
  background: hsl(0, 0%, 53%);
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background: hsl(0, 8%, 72%);
  color: #ffffff;
}


.button.stroke {
  background: transparent;
  border: 3px solid #313131;
  font-size: 1.5rem;
  display: block;
  letter-spacing: 0.2rem;
  height: 6.6rem;
  line-height: 6.6rem;
  padding: 0 3rem;
  margin-top: 4.8rem;
}

.button.stroke:hover {
  border: 3px solid hsl(350, 100%, 30%);
  color: hsl(350, 100%, 30%);
}

.btn-close{
  color: white !important;
background-color: transparent !important;
}

.item-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}

.add-gig-button{
  opacity: 0.4;
  transition: 2;
}



.add-gig-item-cell{
  vertical-align: bottom !important;
  padding-bottom: 3em;
}

.add-gig-button:hover {
  opacity: 1;
}

.folio-tem:hover{
  opacity: 1;
}

.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Ensures the icon does not interfere with the click events on the overlay */
}

.edit-gig-button{
  transition: 2;
}

.edit-icon {
  position: absolute;
  top: 1%;
  left: 84%;
  visibility: hidden;
  pointer-events: none; /* Ensures the icon does not interfere with the click events on the overlay */
}

.edit-gig-button:hover .edit-icon {
  visibility: visible;
}