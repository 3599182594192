/* Generated by Font Squirrel (http://www.fontsquirrel.com) */

/*
 * Lora
================================================================================ */
@font-face {
    font-family: 'lora-bold';
    src: url('../fonts/lora/lora-bold-webfont.eot');
    src: url('../fonts/lora/lora-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora/lora-bold-webfont.woff') format('woff'),
         url('../fonts/lora/lora-bold-webfont.ttf') format('truetype'),
         url('../fonts/lora/lora-bold-webfont.svg#lorabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lora-bold-italic';
    src: url('../fonts/lora/lora-bolditalic-webfont.eot');
    src: url('../fonts/lora/lora-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora/lora-bolditalic-webfont.woff') format('woff'),
         url('../fonts/lora/lora-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/lora/lora-bolditalic-webfont.svg#lorabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lora-italic';
    src: url('../fonts/lora/lora-italic-webfont.eot');
    src: url('../fonts/lora/lora-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora/lora-italic-webfont.woff') format('woff'),
         url('../fonts/lora/lora-italic-webfont.ttf') format('truetype'),
         url('../fonts/lora/lora-italic-webfont.svg#loraitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'lora-regular';
    src: url('../fonts/lora/lora-regular-webfont.eot');
    src: url('../fonts/lora/lora-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora/lora-regular-webfont.woff') format('woff'),
         url('../fonts/lora/lora-regular-webfont.ttf') format('truetype'),
         url('../fonts/lora/lora-regular-webfont.svg#loraregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*
 * Poppins 
================================================================================ */
@font-face {
    font-family: 'poppins-bold';
    src: url('../fonts/poppins/poppins-bold-webfont.eot');
    src: url('../fonts/poppins/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-bold-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-bold-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppins-light';
    src: url('../fonts/poppins/poppins-light-webfont.eot');
    src: url('../fonts/poppins/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-light-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-light-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-light-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-light-webfont.svg#poppinslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppins-medium';
    src: url('../fonts/poppins/poppins-medium-webfont.eot');
    src: url('../fonts/poppins/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-medium-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-medium-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-medium-webfont.svg#poppinsmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppins-regular';
    src: url('../fonts/poppins/poppins-regular-webfont.eot');
    src: url('../fonts/poppins/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'poppins-semibold';
    src: url('../fonts/poppins/poppins-semibold-webfont.eot');
    src: url('../fonts/poppins/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins/poppins-semibold-webfont.woff') format('woff'),
         url('../fonts/poppins/poppins-semibold-webfont.ttf') format('truetype'),
         url('../fonts/poppins/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

